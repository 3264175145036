import React from 'react';
import styled from 'styled-components';


// Error Messages
export const errorMessages = {
  required : "es requerido",
  requiredAlt : "es requerida",
  maxLength: "El valor excede el máximo permitido",
  pattern: "debe tener el formato 'usuario@dominio.com'",
  requiredPlural: "son requeridos",
  patternDni: "debe contener solo números",
};

const InvalidFeedback = styled.span`
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    margin-left: 8px;
    color: #F0323C; 
    font-family: Satoshi;
    font-weight: 200;
`;
// Error Component
export const errorMessage = (errorMsg) => {
  return <InvalidFeedback>{errorMsg}</InvalidFeedback>;
};
