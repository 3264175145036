import React, { lazy, Suspense, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, withRouter } from 'react-router-dom';
import AgentsModule from './containers/Agents/Agents.module';
import Home from './containers/Home';
import Login from './containers/Login/Login';
import { userContext, UserStatus } from './store/contexts';
import { AppointmentActionsContext, defaultValue } from './appointment/shared/infrastructure/index';

const AppointmentAvailability = lazy(
  () => import('./containers/AppointmentAvailability/AppointmentAvailability')
);

const CapturingManagers = lazy(() => import('./containers/CapturingManagers/CapturingManagers'));
const AppointmentHome = lazy(() => import('./containers/AppointmentHome/AppointmentHome'));
const AppointmentsUser = lazy(() => import('./containers/AppointmentsByUser/AppointmentsUser'));

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = useContext(userContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        user && user.status === UserStatus.IS_LOGGED_IN ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/ingreso',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const appointmensAvailability = ({ match }) => (
  <Suspense fallback={<div> Cargando ... </div>}>
    <AppointmentAvailability match={match} />
  </Suspense>
);

const appointmensHome = () => (
  <Suspense fallback={<div> Cargando ... </div>}>
    <AppointmentHome />
  </Suspense>
);

const appointmensUser = () => (
  <Suspense fallback={<div> Cargando ... </div>}>
    <AppointmentActionsContext.Provider value={defaultValue}>
      <AppointmentsUser />
    </AppointmentActionsContext.Provider>
  </Suspense>
);

const Agents = () => (
  <Suspense fallback={<div> Cargando ... </div>}>
    <AgentsModule />
  </Suspense>
);

const capturingManagers = () => (
  <Suspense fallback={<div> Cargando ... </div>}>
    <CapturingManagers />
  </Suspense>
);

export const Routes = () => {
  return (
    <Switch>
      <ScrollToTop>
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute exact path="/agendamientos" component={appointmensHome} />
        <ProtectedRoute
          exact
          path="/agendamientos/crear-disponibilidad/:id"
          component={appointmensAvailability}
        />
        <ProtectedRoute
          exact
          path="/agendamientos/usuario-agendamientos"
          component={appointmensUser}
        />
        <ProtectedRoute exact path="/agentes" component={Agents} />
        <ProtectedRoute path="/gestores" component={capturingManagers} />

        <Route path="/ingreso" component={withRouter(Login)} />
      </ScrollToTop>
    </Switch>
  );
};
