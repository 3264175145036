import { useLazyQuery } from '@apollo/client';
import { ResponseErrorMapping } from '../../../../../shared/gql/response-error.mapper';
import {
  DemonstratorAgent,
  DemonstratorAgentQueryActions,
  GetAllDemonstratorAgentsT,
} from '../../../domain';
import { AllDemonstratorAgentsResponseDto } from '../dtos/demosntrator-agent.dto';
import { GET_DEMONSTRATOR_AGENTS } from '../schemas/query/get-demonstrator-agent.query';

export const GqlDemonstratorAgentQueryActions: DemonstratorAgentQueryActions = {
  useGetAllDemonstratorAgents: (): GetAllDemonstratorAgentsT => {
    const [action, { data, loading, error, refetch }] =
      useLazyQuery<AllDemonstratorAgentsResponseDto>(GET_DEMONSTRATOR_AGENTS, {
        errorPolicy: 'all',
      });

    const mapRecalling = (): Promise<DemonstratorAgent[] | undefined> =>
      (refetch && refetch().then((response) => response.data.getVisitCompanions)) ||
      Promise.resolve(undefined);

    return {
      loading,
      recall: mapRecalling,
      error: error && ResponseErrorMapping(error),
      allDemonstratorAgents: data?.getVisitCompanions,
      getAllDemonstratorAgents: () => action(),
    };
  },
};
