import React from 'react';
import styled from "styled-components";

import logo from "../../assets/aptuno-logo-white.svg";

const Brand = () => {
  return (
    <Image src={logo} alt="Aptuno Logo" />
  )
}

export default Brand

const Image = styled.img`
  height: 85%;
  margin: auto 0;
  max-width: 100%;
`;
