import React, { useContext } from 'react';
import { v4 as uuid } from 'uuid';
import { Icon } from '@aptuno/aptuno-ui';
import {
  SidebarMenu,
  SidebarWrapper,
  MenuLogo,
  MenuSignOut,
  SidebarMenuItem,
} from './Sidebar.styled';
import Brand from '../navbar/Brand';
import { MENU_DATA } from '../../data/menu';
import SubMenu from './Submenu';
import { userContext } from '../../store/contexts';
import { initialUserState, UserAction } from '../../store/userReducer';

export const Sidebar = ({ open, ...props }) => {
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  const user = useContext(userContext);

  const handleSignOut = () => {
    user?.dispatch({
      type: UserAction.USER_SIGN_OUT,
      payload: initialUserState,
    });
  };

  return (
    <SidebarWrapper open={open} aria-hidden={!isHidden} {...props}>
      <MenuLogo tabIndex={tabIndex}>
        <Brand />
      </MenuLogo>
      <SidebarMenu tabIndex={tabIndex}>
        {MENU_DATA.map((item) => (
          <SubMenu item={item} key={uuid()} onClick={() => props.setOpen(false)} />
        ))}
      </SidebarMenu>
      <MenuSignOut>
        <SidebarMenuItem onClick={handleSignOut}>
          <Icon icon={'outbox'} aria-hidden />
          Cerrar Sesion
        </SidebarMenuItem>
      </MenuSignOut>
    </SidebarWrapper>
  );
};
