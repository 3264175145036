import styled from 'styled-components';

type SideBarProps = {
  open: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const SidebarWrapper = styled.div<SideBarProps>`
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100vh;
  width: 270px;
  background: ${(props) => props.theme['primaryDark']};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  color: #fff;
  width: 260px;
  position: absolute;
  z-index: 9;
  padding-bottom: 30px;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
  outline: none;

  @media (max-width: ${({ theme }) => theme['mobile']}) {
    width: 100%;
  }
`;
const SidebarMenu = styled.ul`
  display: flex;
  align-items: left;
  flex-direction: column;
  list-style: none;
  width: 100%;
  padding: 30px 10px;
  overflow: auto;
  outline: none;
`;

const SidebarMenuItem = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  margin: 5px 0;
  outline: none;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;

  &:hover {
    /*box-shadow: inset 3px 0 0 0 #ffffff;*/
    cursor: pointer;
    /*  background: #343b53; */
    color: #5a8dee;
    border-radius: 0.267rem;
    background-color: hsla(0, 0%, 78%, 0.2);
    box-shadow: none;

    & p {
      color: #5a8dee;
    }
  }
`;

const MenuLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
  height: 60px;
  color: #fff;
  margin: 0;
  padding: 15px 15px;
  border-bottom: 1px solid #2e2e33;
  z-index: 4;
  outline: none;
`;

const MenuSignOut = styled.div`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  height: 100px;
  color: #fff;
  padding: 20px 10px;
  border-top: 1px solid #2e2e33;
  position: absolute;
  bottom: 0;
  width: 100%;

  & svg {
    transform: rotate(90deg);
    margin-right: 16px;
  }
`;

export { SidebarWrapper, SidebarMenu, MenuLogo, MenuSignOut, SidebarMenuItem };
