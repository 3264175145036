import { Appointment, AppointmentId } from '../../../domain/models/appointment.model';
import { AppointmentByTenantResponseDTO } from '../dtos/appointments-by-tenant.dto';

export const AppointmentByTenantDTOMapper = {
  toAppointmentsByTenant: (dto: AppointmentByTenantResponseDTO): Appointment[] =>
    dto.getAppointmentsByTenant.map((appointmentDto) => ({
      id: new AppointmentId(appointmentDto.id),
      visitDate: new Date(appointmentDto.visitDate),
      status: appointmentDto.status,
      type: appointmentDto.type,
      estate: appointmentDto.estate,
      tenant: appointmentDto.tenant,
      visitAssistant: appointmentDto.visitAssistant,
      visitCompanion: appointmentDto.visitCompanion,
    })),
};
