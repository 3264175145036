import React, { useReducer } from 'react';
import './App.css';

import Layout from './components/Layout/Layout';
import { Routes } from './router';
import { initialUserState, userReducer } from './store/userReducer';
import Providers from './Providers';

function App() {
  const [userState, userStateDispatch] = useReducer(
    userReducer,
    initialUserState
  );

  return (
    <Providers>
      <Layout>{Routes()}</Layout>
    </Providers>
  );
}

export default App;
