import { gql } from "@apollo/client";
import { AuthenticatedUser } from "../store/contexts";

export interface AuthResponse {
  authenticate: {
    token?: string;
    userData?: AuthenticatedUser;
  };
}

export const AUTHENTICATE = gql`
  mutation authenticate($password: String!, $userName: String!) {
    authenticate(password: $password, userName: $userName) {
      token
      userData {
        firstName
        lastName
        role
        status
      }
    }
  }
`;
