import React, { PropsWithChildren, useReducer } from 'react';
import { initialUserState, userReducer } from './store/userReducer';
import { ToastProvider, themeDefs } from '@aptuno/aptuno-ui';
import GlobalStyles from './styles/Global';
import { userContext } from './store/contexts';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/theme';

type ProvidersProps = {
  children: React.ReactNode;
};

const ThemeProviderFixed = ThemeProvider as unknown as React.FC<PropsWithChildren<{ theme: unknown }>>;

const Providers = ({ children }: ProvidersProps) => {
  const getInitialUserStatus = () => {
    const data = localStorage.getItem('user-data');
    if (data) {
      return JSON.parse(data);
    }
    return initialUserState;
  };

  const [userState, userStateDispatch] = useReducer(userReducer, getInitialUserStatus());

  return (
    <ThemeProviderFixed theme={{ ...themeDefs.light, ...theme }}>
      <userContext.Provider value={{ ...userState, dispatch: userStateDispatch }}>
        <ToastProvider variant='top_right'>{children}</ToastProvider>
      </userContext.Provider>
      <GlobalStyles />
    </ThemeProviderFixed>
  );
};

export default Providers;
