export enum AppointmentStatus {
  BEING_CONFIRMED_BY_LANDLORD = 'BEING_CONFIRMED_BY_LANDLORD',
  BEING_CONFIRMED_BY_OPS = 'BEING_CONFIRMED_BY_OPS',
  BEING_CONFIRMED_BY_VISIT_COMPANIONS = 'BEING_CONFIRMED_BY_VISIT_COMPANIONS',
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  CREATED = 'CREATED',
  LANDLORD_CONFIRMED = 'LANDLORD_CONFIRMED',
  VISITED = 'VISITED',
}

export const AppointmentStatusModule = {
  finalStatuses: [AppointmentStatus.CANCELED, AppointmentStatus.VISITED],

  isOpen: (status: AppointmentStatus): boolean =>
    !AppointmentStatusModule.finalStatuses.some((final) => status === final),

  isScheduled: (status: AppointmentStatus): boolean => AppointmentStatus.CREATED === status,

  isBeingConfirmedByLandlord: (status: AppointmentStatus): boolean =>
    AppointmentStatus.BEING_CONFIRMED_BY_LANDLORD === status,
};
