import { createGlobalStyle } from 'styled-components';

const GlobalStyles: any = createGlobalStyle`
  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url("/fonts/Satoshi-Regular.woff2");
  }

  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("/fonts/Satoshi-Medium.woff2");
  }

  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/fonts/Satoshi-Bold.woff2");
  }

  @font-face {
    font-family: "Satoshi";
    font-weight: 500, bold;
    font-display: swap;
    src: url("/fonts/Satoshi-Bold.woff2") format("woff2"), url("/fonts/Satoshi-Bold.woff") format("woff");
  }

  @font-face {
    font-family: "Satoshi";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("/fonts/Satoshi-Black.woff2");
  }

  @font-face {
    font-family: "icomoon";
    src: url("/fonts/icomoon.eot?hbfemh");
    src: url("/fonts/icomoon.eot?hbfemh#iefix") format("embedded-opentype"),
    url("/fonts/icomoon.ttf?hbfemh") format("truetype"), url("/fonts/icomoon.woff?hbfemh") format("woff"),
    url("/fonts/icomoon.svg?hbfemh#icomoon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  *,
  *::after,
  *::before {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    box-sizing: border-box;
    font-family: "Satoshi", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #333333;

    input, :active, span, div {
      outline: none;
    }
  }

  .rfs-control-container {
    border-radius: 16px !important;
    height: 48px !important;
    border: none !important;
    box-shadow: 10px 10px 25px 10px rgb(108 117 125 / 10%) !important;
  }

  .rfs-menu-container {
    background: white;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 0px 4px #d2d2d2;
    overflow: hidden;
  }

  .react-tel-input {
    width: 100%;

    & .form-control,
    & .selected-flag:hover,
    & .flag-dropdown {
      background-color: white !important;
      border-radius: 16px !important;
      border: none !important;
      box-shadow: 10px 10px 25px 10px rgba(108, 117, 125, 0.1) !important;
      height: 48px !important;
    }

    & .form-control {
      overflow: hidden;
      width: 100% !important;
      padding-left: 58px !important;
    }

    & .selected-flag,
    & .flag-dropdown {
      width: 48px !important;
    }

    & .open {
      background: none !important;
    }

    & .country-list {
      background-color: white !important;
      border-radius: 16px !important;
      border: none !important;
      box-shadow: 10px 10px 25px 10px rgba(108, 117, 125, 0.1) !important;
      overflow-y: auto !important;

      .country, .dial-code {
        color: black !important;
      }

      .highlight {
        background-color: #007bff !important;
        color: #ffffff !important;

        .dial-code {
          color: #ffffff !important;
        }
      }

      .country:hover {
        color: black !important;
        background-color: rgba(0, 123, 255, 0.15) !important;
      }

      .dial-code:hover {
        color: black !important;
      }
    }
  }
`;

export default GlobalStyles;
