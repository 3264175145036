import React, { lazy, Suspense } from 'react';
import { Route, useRouteMatch} from 'react-router-dom';

const AgentsHome = lazy(() => import('./AgentsHome'));

const AgentsModule = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route exact path={`${path}/`}>
        <Suspense fallback={<div> Cargando ... </div>}>
          <AgentsHome />
        </Suspense>
      </Route>
      
    </>
  );
};

export default AgentsModule;
