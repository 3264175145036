import React, { useContext, useRef, useState } from 'react';
import { Router } from 'react-router-dom';
import history from '../../history';
import { userContext, UserStatus } from '../../store/contexts';
import styled from 'styled-components';
import GlobalStyles from '../../styles/Global';
import { Container } from 'react-awesome-styled-grid';
import { useOnClickOutside } from '../../shared/hooks';
import { Sidebar } from '../Sidebar/Sidebar';
import Burger from '../Burger';
import FocusLock from 'react-focus-lock';
import Navbar from '../navbar/Navbar';

const Wrapper = styled.div`
  position: relative;
  top: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
`;

type MainWrapperProps = {
  open: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const MainWrapper = styled.div<MainWrapperProps>`
  transform: ${({ open }) => (open ? 'translateX(260px)' : 'translateX(0)')};
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  width: ${({ open }) => (open ? 'calc(100% - 260px)' : '100%')};
  margin: 0;
  padding: ${({ open }) => (open ? '16px' : '16px 64px')};

  @media (max-width: ${({ theme }) => theme['mobile']}) {
    padding: 10px;
    overflow: hidden;
  }
`;

const MainWrapperBody = styled.div`
  margin-top: 70px;
  height: 100%;
`;

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const node = useRef(null);
  const menuId = 'main-menu';
  const user = useContext(userContext);

  useOnClickOutside(node, () => setOpen(false));

  return (
    <>
      <GlobalStyles />
      <Router history={history}>
        {user && user.status === UserStatus.IS_LOGGED_IN && (
          <Wrapper>
            <div ref={node}>
              <FocusLock disabled={!open}>
                <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
                <Sidebar open={open} setOpen={setOpen} id={menuId} />
              </FocusLock>
            </div>
            <MainWrapper open={open}>
              <Container fluid>
                <Navbar links={[]} userName={user.data.firstName} />
                <MainWrapperBody>{children}</MainWrapperBody>
              </Container>
            </MainWrapper>
          </Wrapper>
        )}
        {user && user.status === UserStatus.IS_NOT_LOGGED && children}
      </Router>
    </>
  );
};

export default Layout;
