import React from 'react';
import { StyledBurger } from './Burger.styled';

type BurgerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
} & React.HTMLAttributes<HTMLButtonElement>;

const Burger = ({ open, setOpen, ...props }: BurgerProps) => {
  return (
    <StyledBurger
      aria-label="Toggle menu"
      aria-expanded={open}
      open={open}
      onClick={(event) => setOpen(!open)}
      {...props}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

export default Burger;
