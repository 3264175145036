import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

/* Apollo */
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import { getToken } from "./shared/coolFunctions";
/* end apollo client imports*/

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  const authorization = token ? `Bearer ${token}` : undefined;
  return {
    uri: process.env.REACT_APP_GRAPHQL_SERVER_URI,
    headers: {
      ...headers,
      authorization,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ extensions, path }) =>
      extensions?.exception.errors.map((error) =>
        console.log(
          `[GraphQL error]: Message: ${error.code}, Location: ${extensions?.code}, Path: ${path}`
        )
      )
    );
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorLink.concat(
    authLink.concat(
      createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_SERVER_URI })
    )
  ),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
