import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Icon } from '@aptuno/aptuno-ui';

type SidebarMenuItemProps = {
  isActive: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

const SidebarMenuItem = styled.span<SidebarMenuItemProps>`
  display: flex;
  color: white;
  display: flex;
  height: 40px;
  width: 100%;
  margin: 5px 0;
  outline: none;
  text-decoration: none;
  text-decoration: none;
  align-items: center;
  padding: 5px 16px;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    color: #5a8dee;
    border-radius: 0.267rem;
    background-color: hsla(0, 0%, 78%, 0.2);
    box-shadow: 0px 10px 10px 0px rgb(108 117 125 / 10%);

    & p,
    a,
    span {
      color: #5a8dee;
    }
  }

  background-color: ${({ isActive }) => (isActive ? 'hsla(0,0%,78%,.2)' : 'none')};

  & p,
  a,
  span {
    color: ${({ isActive }) => (isActive ? '#5a8dee' : '#ffffff')};
  }
`;

const SidebarLink = styled(Link)<{ isActive: boolean }>`
  display: flex;
  color: white;
  height: 40px;
  width: 100%;
  margin: 5px 0;
  outline: none;
  text-decoration: none;
  align-items: center;
  padding: 5px 16px;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
    color: #5a8dee;
    border-radius: 0.267rem;
    background-color: hsla(0, 0%, 78%, 0.2);
    box-shadow: 0px 10px 10px 0px rgb(108 117 125 / 10%);

    & p,
    a,
    span {
      color: #5a8dee;
    }
  }

  background-color: ${({ isActive }) => (isActive ? 'hsla(0,0%,78%,.2)' : 'none')};

  & p,
  a,
  span {
    color: ${({ isActive }) => (isActive ? '#5a8dee' : '#ffffff')};
  }
`;

const SidebarLabel = styled.span`
  font-family: 'Satoshi', 'Roboto', sans-serif;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  text-align: left;
  padding: 12px 16px;
  outline: none;
  width: 100%;
`;

const SubMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border: 1px solid #414757;
  border-radius: 8px;
`;

const SubMenu = (props) => {
  const [subnav, setsubnav] = useState(false);
  const showsubnav = () => setsubnav(!subnav);

  const isActive = (url) => (url === window.location.pathname);

  const handleItemNavClic = () => {
    props.item.subnav && showsubnav();
    props.onClick();
  };
  return (
    <>
      {props.item.url && (
        <SidebarLink
          isActive={isActive(props.item.url)}
          to={props.item.url}
          onClick={handleItemNavClic}
        >
          <Icon icon={props.item.icon} aria-hidden />
          <SidebarLabel>{props.item.name}</SidebarLabel>
          {props.item.subnav && subnav ? (
            <Icon icon={props.item.iconOpened} aria-hidden />
          ) : props.item.subnav ? (
            <Icon icon={props.item.iconClosed} aria-hidden />
          ) : null}
        </SidebarLink>
      )}
      {!props.item.url && (
        <SidebarMenuItem
          isActive={!!isActive(props.item.url)}
          // to={props.item.url}
          onClick={props.item.subnav && showsubnav}
        >
          <Icon icon={props.item.icon} aria-hidden />
          <SidebarLabel>{props.item.name}</SidebarLabel>
          {props.item.subnav && subnav ? (
            <Icon icon={props.item.iconOpened} aria-hidden />
          ) : props.item.subnav ? (
            <Icon icon={props.item.iconClosed} aria-hidden />
          ) : null}
        </SidebarMenuItem>
      )}
      {subnav && (
        <SubMenuWrapper>
          {props.item.subnav.map((item) => (
            <SidebarLink
              onClick={() => props.onClick()}
              to={item.url}
              key={uuid()}
              isActive={isActive(item.url)}
            >
              <Icon icon={item.icon} aria-hidden />
              <SidebarLabel>{item.name}</SidebarLabel>
            </SidebarLink>
          ))}
        </SubMenuWrapper>
      )}
    </>
  );
};

export default SubMenu;
