export const MENU_DATA = [
  {
    name: 'Inicio',
    url: '/',
    icon: 'home',
  },
  {
    name: 'Agendamiento',
    icon: 'calendar',
    iconOpened: 'arrowup',
    iconClosed: 'chevrondown',
    url: '',
    subnav: [
      {
        name: 'Disponibilidad Masiva',
        url: '/agendamientos',
        icon: 'dashboard',
      },
      {
        name: 'Crear Disponibilidad',
        url: '/agendamientos/crear-disponibilidad',
        icon: 'edit',
      },
      {
        name: 'Agendamientos por Usuario',
        url: '/agendamientos/usuario-agendamientos',
        icon: 'listunordered',
      },
      {
        name: 'Agentes',
        url: '/agentes',
        icon: 'users',
      },
    ],
  },
  {
    name: 'Captadores',
    icon: 'calendar',
    iconOpened: 'arrowup',
    iconClosed: 'chevrondown',
    url: '',
    subnav: [
      {
        name: 'Administración de Gestores',
        url: '/gestores',
        icon: 'edit',
      },
    ],
  },
];
