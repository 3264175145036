import React, { createContext } from "react";

export enum UserStatus {
  IS_LOGGED_IN = 'IS_LOGGED_IN',
  IS_NOT_LOGGED = 'IS_NOT_LOGGED'
}

export type AuthenticatedUser = {
  firstName: string,
  lastName: string,
  role: string,
  status: string
}

type ErrorType = {
  error: string;
  data: any;
}
export type UserContextType = {
  status: UserStatus | ErrorType;
  data: AuthenticatedUser;
  dispatch: React.Dispatch<{ type: string; payload: unknown }>;
};

export const userContext = createContext<UserContextType | null>(null);
