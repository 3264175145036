import gql from 'graphql-tag';

export const CONFIRM_APPOINTMENT_BY_OPS = gql`
  mutation ConfirmAppointment(
    $visitCompanionId: Int!
    $newVisitDate: Float!
    $appointmentId: Int!
    $instructions: String
  ) {
    confirmAppointment(
      visitCompanionId: $visitCompanionId
      newVisitDate: $newVisitDate
      appointmentId: $appointmentId
      instructions: $instructions
    ) {
      id
    }
  }
`;
