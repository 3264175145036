import React, { useContext, useEffect } from "react";
import { LoginWrapper, Form, Button, HeaderSection } from "./Login.styled";
import { Col, Container, Row } from "react-awesome-styled-grid";
import { Input } from "@aptuno/aptuno-ui";
import { userContext, UserStatus } from "../../store/contexts";
import { useForm, Controller } from "react-hook-form";
import { errorMessage, errorMessages } from "../../components/ErrorMsg";
import Brand from "../../components/navbar/Brand";
import { AUTHENTICATE, AuthResponse } from "../../data-access/User.mutations";
import { useMutation } from "@apollo/client";
import { saveToken } from "../../shared/coolFunctions";
import { UserAction } from "../../store/userReducer";

const style = {
  margin: "8px 0",
};

// TYPES

type AuthFormData = { userName: string; password: string };

// COMPONENT
const Login = (props) => {
  const user = useContext(userContext);
  const { control, handleSubmit, errors } = useForm();
  const { from } = props.location.state || { from: { pathname: "/" } };

  const [authenticate, { loading, error }] = useMutation<AuthResponse>(
    AUTHENTICATE
  );

  const onSubmitForm = (data: AuthFormData) => {
    authenticate({ variables: { ...data } }).then((result) => {
      saveToken(result?.data?.authenticate.token!);
      user?.dispatch({
        type: UserAction.USER_FETCH_DATA_UPDATE,
        payload: {
          data: { ...result?.data?.authenticate.userData },
          status: UserStatus.IS_LOGGED_IN,
        },
      });
      props.history.push(from.pathname);
    }).catch(error => null);
  };

  useEffect(() => {
    if (user?.status === UserStatus.IS_LOGGED_IN)
      props.history.push(from.pathname);
  }, [user]);

  return (
    <LoginWrapper>
      <Container>
        <Form onSubmit={handleSubmit(onSubmitForm)}>
          <Row>
            <Col xs={12}>
              <HeaderSection>
                <Brand />
                <div>
                  <h1>Panel Admin</h1>
                </div>
              </HeaderSection>
            </Col>
            <Col xs={12} style={style}>
              <Controller
                name="userName"
                control={control}
                defaultValue=""
                render={({ onChange }) => (
                  <Input autoFocus id="username" label="Usuario" onChange={onChange} />
                )}
                rules={{ required: true }}
              />
              {errors.username &&
                errors.username.type === "required" &&
                errorMessage(`Usuario ${errorMessages.required}`)}
            </Col>
            <Col xs={12} style={style}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ onChange }) => (
                  <Input
                    id="password"
                    type="password"
                    label="Contraseña"
                    onChange={onChange}
                  />
                )}
                rules={{ required: true }}
              />
              {errors.password &&
                errors.password.type === "required" &&
                errorMessage(`Contraseña ${errorMessages.requiredAlt}`)}
            </Col>
            <Col xs={12} style={style}>
              <Button>Iniciar Sesión</Button>
            </Col>
            {error &&
              errorMessage('usuario o clave incorrectos')}
          </Row>
        </Form>
      </Container>
    </LoginWrapper>
  );
};

export default Login;
