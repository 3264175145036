import { useLazyQuery } from '@apollo/client';
import { ResponseErrorMapping } from '../../../../../shared/gql/response-error.mapper';
import {
  Appointment,
  AppointmentQueryActions,
  GetTenantAppointmentsQuery,
  GetTenantAppointmentsT,
} from '../../../domain';
import { AppointmentByTenantDTOMapper } from '../data-mappers/appointments-by-tenant.mapper';
import {
  AppointmentByTenantRequestDTO,
  AppointmentByTenantResponseDTO,
} from '../dtos/appointments-by-tenant.dto';
import { GET_APPOINTMENT_BY_TENANT } from '../schemas/query/get-appointment-by-tenant.query';

export const GqlAppointmentQueryActions: AppointmentQueryActions = {
  useGetTenantAppointments: (): GetTenantAppointmentsT => {
    const [action, { data, loading, error, refetch }] = useLazyQuery<
      AppointmentByTenantResponseDTO,
      AppointmentByTenantRequestDTO
    >(GET_APPOINTMENT_BY_TENANT, {
      errorPolicy: 'all',
    });

    const mapRecalling = (): Promise<Appointment[] | undefined> =>
      (refetch &&
        refetch().then(
          (response) =>
            response.data && AppointmentByTenantDTOMapper.toAppointmentsByTenant(response.data)
        )) ||
      Promise.resolve(undefined);

    return {
      loading,
      recall: mapRecalling,
      error: error && ResponseErrorMapping(error),
      tenantAppointments: data && AppointmentByTenantDTOMapper.toAppointmentsByTenant(data),
      getTenantAppointments: (query: GetTenantAppointmentsQuery) =>
        action({
          variables: {
            tenantAppointmentsFilter: query,
          },
        }),
    };
  },
};
