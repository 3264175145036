import React from 'react';
import {
  AppointmentCommandActions,
  AppointmentQueryActions,
  DemonstratorAgentQueryActions,
  EstateAvailabilityQueryActions,
} from '../../domain';
import { GqlAppointmentQueryActions } from '../gql/adapters/gql-appointment-query.actions';
import { GqlAppointmentCommandActions } from '../gql/adapters/gql-appointment-command.actions';
import { GqlDemonstratorAgentQueryActions } from '../gql/adapters/gql-demonstrator-agent-query.actions';
import { GqlEstateAvailabilityQueryActions } from '../gql/adapters/gql-estate-availability-query.actions';

type AppointmentActionContextT = {
  appointmentQueryActions: AppointmentQueryActions;
  appointmentCommandActions: AppointmentCommandActions;
  demonstratorAgentQueryActions: DemonstratorAgentQueryActions;
  estateAvailabilityQueryActions: EstateAvailabilityQueryActions;
};

export const defaultValue: AppointmentActionContextT = {
  appointmentQueryActions: GqlAppointmentQueryActions,
  appointmentCommandActions: GqlAppointmentCommandActions,
  demonstratorAgentQueryActions: GqlDemonstratorAgentQueryActions,
  estateAvailabilityQueryActions: GqlEstateAvailabilityQueryActions,
};

export const AppointmentActionsContext =
  React.createContext<AppointmentActionContextT>(defaultValue);
