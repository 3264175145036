import gql from 'graphql-tag';

export const GET_APPOINTMENT_BY_TENANT = gql`
  query GetAppointmentByTenant($tenantAppointmentsFilter: TenantAppointmentsFilterInputType!) {
    getAppointmentsByTenant(TenantAppointmentsFilter: $tenantAppointmentsFilter) {
      id
      estate {
        id
        legalId
        address
        pricing {
          quote
          admonPrice
          currencyISO
        }
      }
      visitDate
      type
      status
      tenant {
        userId
        firstName
        lastName
        phoneCode
        phoneNumber
        identityDocumentType
        identityDocumentCode
        email
      }
      visitAssistant {
        userId
        firstName
        lastName
        phoneCode
        phoneNumber
        identityDocumentType
        identityDocumentCode
        email
      }
      visitCompanion {
        userId
        firstName
        lastName
        phoneCode
        phoneNumber
        identityDocumentType
        identityDocumentCode
      }
      instructions
    }
  }
`;
