import gql from 'graphql-tag';

export const GET_DEMONSTRATOR_AGENTS = gql`
  query GetVisitCompanions {
    getVisitCompanions {
      userId
      firstName
      lastName
      phoneNumber
      phoneCode
      email
      identityDocumentType
      identityDocumentCode
      role
    }
  }
`;
