import { ApolloError } from '@apollo/client';
import { AptunoError } from '../Models/error.model';

export const ResponseErrorMapping = (error: ApolloError): AptunoError => {
  const domainError: AptunoError | undefined =
    error?.graphQLErrors[0]?.extensions?.exception?.errors?.[0];

  return (
    domainError || {
      code: error.networkError ? 'NETWORK_ERROR' : 'DEFAULT',
      type: 'UNKNOWN',
    }
  );
};
