import gql from 'graphql-tag';

export const GET_WEEKLY_ESTATE_AVAILABILITY = gql`
  query EstateVisitAvailability($date: Float!, $estateId: Int!) {
    estateVisitAvailability(date: $date, estateId: $estateId) {
      estateId
      availability {
        date
        weekDay
        slots {
          date
          enabled
        }
      }
    }
  }
`;
