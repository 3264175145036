import { useMutation } from '@apollo/client';
import { ResponseErrorMapping } from '../../../../../shared/gql/response-error.mapper';
import {
  AppointmentId,
  AppointmentCommandActions,
  ConfirmAppointmentByLandlordCommand,
  ConfirmAppointmentByOpsCommand,
  ConfirmAppointmentByLandlordT,
  ConfirmAppointmentByOpsT,
} from '../../../domain';
import { ConfirmAppointmentByLandlordResponseDto } from '../dtos/confirm-appointment-by-landlord.dto';
import { ConfirmAppointmentByOpsResponseDto } from '../dtos/confirm-appointment-by-ops.dto';
import { CONFIRM_APPOINTMENT_BY_LANDLORD } from '../schemas/mutation/confirm-appointment-by-landlord.mutation';
import { CONFIRM_APPOINTMENT_BY_OPS } from '../schemas/mutation/confirm-appointment-by-ops.mutation';

export const GqlAppointmentCommandActions: AppointmentCommandActions = {
  useConfirmAppointmentByLandlord: (): ConfirmAppointmentByLandlordT => {
    const [action, { data, error }] = useMutation<
      ConfirmAppointmentByLandlordResponseDto,
      ConfirmAppointmentByLandlordCommand
    >(CONFIRM_APPOINTMENT_BY_LANDLORD);

    return {
      result: !!data
        ? data.landlordConfirmAppointment.map((value) => new AppointmentId(value.id))
        : undefined,
      error: error && ResponseErrorMapping(error),
      confirmAppointmentByLandlord: (command: ConfirmAppointmentByLandlordCommand) =>
        action({ variables: command }),
    };
  },

  useConfirmAppointmentByOps: (): ConfirmAppointmentByOpsT => {
    const [action, { data, error }] = useMutation<
      ConfirmAppointmentByOpsResponseDto,
      ConfirmAppointmentByOpsCommand
    >(CONFIRM_APPOINTMENT_BY_OPS);

    return {
      result: !!data
        ? data.confirmAppointment.map((value) => new AppointmentId(value.id))
        : undefined,
      error: error && ResponseErrorMapping(error),
      confirmAppointmentByOps: (command: ConfirmAppointmentByOpsCommand) =>
        action({ variables: command }),
    };
  },
};
