import { AppointmentEstate } from './appointment-estate.model';
import { AppointmentStatus, AppointmentStatusModule } from './appointment-status.model';
import { AppointmentTenant, DemonstratorAgent, VisitAssistant } from './appointment-user.model';

export enum AppointmentTypes {
  faceToFaceMultiple = 'FACE_2_FACE_MULTIPLE',
  faceToFaceAlone = 'FACE_2_FACE_ALONE',
  virtual = 'VIRTUAL',
}

export class AppointmentId {
  public readonly _tag = 'AppointmentId';

  constructor(public readonly value: number) {}
}

export type Appointment = {
  id: AppointmentId;
  estate: AppointmentEstate;
  visitDate: Date;
  type: AppointmentTypes;
  status: AppointmentStatus;
  tenant: AppointmentTenant;
  visitAssistant: VisitAssistant;
  visitCompanion: DemonstratorAgent;
  instructions?: string;
  suggestedInstructions?: string;
};

export const AppointmentModule = {
  isOpen: (appointment: Appointment): boolean => AppointmentStatusModule.isOpen(appointment.status),

  isScheduled: (appointment: Appointment): boolean =>
    AppointmentStatusModule.isScheduled(appointment.status),

  isBeingConfirmedByLandlord: (appointment: Appointment): boolean =>
    AppointmentStatusModule.isBeingConfirmedByLandlord(appointment.status),

  canBeConfirmedByLandlord: (appointment: Appointment): boolean =>
    AppointmentModule.isOpen(appointment) &&
    (AppointmentModule.isScheduled(appointment) || AppointmentModule.isBeingConfirmedByLandlord(appointment)),

  canBeConfirmedByOps: (appointment: Appointment): boolean => AppointmentModule.isOpen(appointment),
};
