import { useLazyQuery } from '@apollo/client';
import { ResponseErrorMapping } from '../../../../../shared/gql/response-error.mapper';
import {
  EstateAvailabilityQueryActions,
  GetEstateWeeklyCalendarT,
  GetWeeklyEstateCalendarQuery,
  WeeklyEstateAvailability,
} from '../../../domain';
import {
  WeeklyEstateAvailabilityRequestDto,
  WeeklyEstateAvailabilityResponseDto,
} from '../dtos/weekly-estate-availability.dto';
import { GET_WEEKLY_ESTATE_AVAILABILITY } from '../schemas/query/get-weekly-estate-availability.query';

export const GqlEstateAvailabilityQueryActions: EstateAvailabilityQueryActions = {
  useGetWeeklyEstateCalendar: (): GetEstateWeeklyCalendarT => {
    const [action, { data, loading, error, refetch }] = useLazyQuery<
      WeeklyEstateAvailabilityResponseDto,
      WeeklyEstateAvailabilityRequestDto
    >(GET_WEEKLY_ESTATE_AVAILABILITY, {
      errorPolicy: 'all',
    });

    const mapRecalling = (): Promise<WeeklyEstateAvailability | undefined> =>
      (refetch && refetch().then((response) => response.data.estateVisitAvailability)) ||
      Promise.resolve(undefined);

    return {
      loading,
      recall: mapRecalling,
      error: error && ResponseErrorMapping(error),
      weeklyEstateAvailability: data?.estateVisitAvailability,
      getWeeklyEstateCalendar: (query: GetWeeklyEstateCalendarQuery) =>
        action({
          variables: {
            estateId: query.estateId,
            date: query.weekStartAt.getTime(),
          },
        }),
    };
  },
};
