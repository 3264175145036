import styled from 'styled-components';

export const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #48515a;
  color: white;
`;
export const Form = styled.form`
  margin: 0 auto;
  width: 100%;
  max-width: 414px;
  padding: 3.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #272e48;
  border-radius: 10px;
  box-shadow: -8px 12px 20px 0 rgba(11, 26, 51, 0.63) !important;

  @media (max-width: ${({ theme }) => theme['mobile']}) {
  }
`;

export const Button = styled.button`
  max-width: 100%;
  padding: 11px 13px;
  color: rgb(253, 249, 243);
  font-weight: 600;
  text-transform: uppercase;
  background: #0055fb;
  border: none;
  border-radius: 16px;
  outline: 0;
  cursor: pointer;
  margin-top: 0.6rem;
  transition: all 0.3s ease-out;
  :hover {
    background: #4e85f3;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`;

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  justify-content: center;
  align-items: center;

  & img {
    max-height: 48px;
  }

  & h1 {
    padding: 16px 0;
    font-size: 28px;
  }
`;
