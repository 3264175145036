import { removeUserSesion } from "../shared/coolFunctions";
import { UserStatus } from "./contexts";

export const initialUserState = {
  status: UserStatus.IS_NOT_LOGGED,
};

export enum UserAction {
  USER_FETCHING_DATA_UPDATE = 'USER_FETCHING_DATA_UPDATE',
  USER_FETCH_ERROR_DATA_UPDATE = 'USER_FETCH_ERROR_DATA_UPDATE',
  USER_FETCH_DATA_UPDATE = 'USER_FETCH_DATA_UPDATE',
  USER_SIGN_OUT = 'USER_SIGN_OUT'
}

export const userReducer = (state, action) => {
  switch (action.type) {
    case UserAction.USER_FETCHING_DATA_UPDATE:
    case UserAction.USER_FETCH_ERROR_DATA_UPDATE:
    case UserAction.USER_FETCH_DATA_UPDATE: 
      const newState = Object.assign({}, state, action.payload)
      localStorage.setItem('user-data', JSON.stringify(newState));
      return newState;
    case UserAction.USER_SIGN_OUT: {
      removeUserSesion();
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
};

