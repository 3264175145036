import { useSpring, config } from 'react-spring';
import { Avatar } from '@aptuno/aptuno-ui';
import { FlexContainer, NavBar, NavLinks } from './Navbar.styled';
interface LinkNavbar {
  name: string;
  path: string;
}
export interface NavbarPropsTypes {
  links: Array<LinkNavbar>;
  userName: string;
}

const Navbar = ({ links = [], userName }: NavbarPropsTypes) => {
  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)',
  });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly,
  });

  return (
    <NavBar style={barAnimation}>
      <FlexContainer>
        {links.length > 0 && (
          <NavLinks style={linkAnimation}>
            {links.map((link) => (
              <a href={link.path}>{link.name}</a>
            ))}
          </NavLinks>
        )}
        <Avatar loading={false} username={userName} src="" size="medium" />
      </FlexContainer>
    </NavBar>
  );
};

export default Navbar;
