import currency from './currency';

export const toggleBodyScroll = (val) => {
  const body = document.querySelector('body');
  if (val !== null && val !== undefined) {
    if (val) body!.classList.remove('no-scroll');
    else body!.classList.add('no-scroll');
  } else body!.classList.toggle('no-scroll');
};

export const sortByPropertyName = (key) => {
  return function (a, b) {
    if (a[key] > b[key]) {
      return 1;
    } else if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  };
};

export function saveToken(token: string): void {
  localStorage.setItem('token', String(token));
}

export function getToken(): string | null {
  return localStorage.getItem('token');
}

export function removeUserSesion(): void {
  localStorage.removeItem('token');
  localStorage.removeItem('user-data');
}

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) => {
  return currency(amount, {
    formatWithSymbol: false,
    precision: 0,
    separator: '.',
  })?.format(true);
};

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
};

export const extractPhoneCode = (phone) => {
  return phone?.substr(0, 3);
};

export const extractPhoneNumber = (phone) => {
  return phone?.substr(3);
};
